import React, {
  FC,
  useMemo,
  useEffect,
  useLayoutEffect,
  useState,
  useRef,
  useContext,
} from 'react'
import { Environment } from 'relay-runtime'
import { useLazyLoadQuery, graphql } from 'react-relay'

import {
  Route,
  Routes,
  Navigate,
  useLocation,
  useNavigate,
  useParams,
  Location,
  Router,
  useMatch,
} from 'react-router-dom'

import { lazy as loadable } from '@loadable/component'
import { ServerContext } from '@data/ServerContext'

import { TamaguiProvider } from 'tamagui'
import tamaguiConfig from '../config/tamagui.config'

// import '@tamagui/core/reset.css'

import {
  ErrorBoundary,
  lightComponents,
  ModalWrapper,
  Provider,
  tokens,
  // wrapSuspenseComponent,
  wrapSuspenseScreen,
  SuspenseScreen,
  DynamicIsland,
} from '@components'

import { Footer } from '@newComponents/Footer'

import { Provider as DataProvider } from '@data/Provider'

import { wrapSuspenseComponent } from '@newComponents/SuspenseWrapper'

import * as Sentry from '@sentry/react'

const MAINTENANCE_MODE = false

import { useMeta, useTitle } from 'hoofd'

import { AppQuery } from './__generated__/AppQuery.graphql'

const HomeScreen = wrapSuspenseScreen(
  loadable(() => import('./screens/HomeScreen/HomeScreen'))
)

const WrappedActorNavigator = wrapSuspenseScreen(
  loadable(() => import('./navigators/ActorNavigator'))
)
const WrappedEventNavigator = wrapSuspenseScreen(
  loadable(() => import('./navigators/EventNavigator'))
)

const GuildsScreenNew = wrapSuspenseScreen(
  loadable(() => import('./screens/GuildsScreen/GuildsScreenNew'))
)

const GuildNewScreen = wrapSuspenseScreen(
  loadable(() => import('./screens/GuildNewScreen/GuildNewScreen'))
)

const GuildNewContent = wrapSuspenseScreen(
  loadable(() => import('./screens/GuildNewScreen/GuildNewContent'))
)

const EventsScreen = wrapSuspenseScreen(
  loadable(() => import('./screens/EventsScreen/EventsScreen'))
)

const PresentationsScreen = wrapSuspenseScreen(
  loadable(() => import('./screens/PresentationsScreen/PresentationsScreen'))
)

const MeetupIntegrationScreen = wrapSuspenseScreen(
  loadable(
    () => import('./screens/MeetupIntegrationScreen/MeetupIntegrationScreen')
  )
)

const BlueskyIntegrationScreen = wrapSuspenseScreen(
  loadable(
    () => import('./screens/BlueskyIntegrationScreen/BlueskyIntegrationScreen')
  )
)

const PresentationScreen = wrapSuspenseScreen(
  loadable(() => import('./screens/PresentationScreen/PresentationScreen'))
)

const PresentationScreenContentWrapper = wrapSuspenseComponent(
  loadable(
    () =>
      import('./screens/PresentationScreen/PresentationScreenContentWrapper')
  )
)

const PresentationEditScreen = wrapSuspenseScreen(
  loadable(
    () => import('./screens/PresentationEditScreen/PresentationEditScreen')
  )
)

const PresentationEditScreenContent = wrapSuspenseScreen(
  loadable(
    () =>
      import('./screens/PresentationEditScreen/PresentationEditScreenContent')
  )
)

const EventNewScreen = wrapSuspenseScreen(
  loadable(() => import('./screens/EventNewScreen/EventNewScreen'))
)

const PresentationNewScreen = wrapSuspenseScreen(
  loadable(
    () => import('./screens/PresentationNewScreen/PresentationNewScreen')
  )
)
const PresentationNewScreenContent = wrapSuspenseComponent(
  loadable(
    () => import('./screens/PresentationNewScreen/PresentationNewScreenContent')
  )
)

const EmbedNavigator = wrapSuspenseComponent(
  loadable(() => import('./navigators/EmbedNavigator'))
)

const EventNewContent = wrapSuspenseComponent(
  loadable(() => import('./screens/EventNewScreen/EventNewContent'))
)

const EventEditPresentationNewContent = wrapSuspenseComponent(
  loadable(
    () =>
      import(
        './screens/EventEditPresentationNewScreen/EventEditPresentationNewContent'
      )
  )
)

const SignUpScreen = wrapSuspenseScreen(
  loadable(() => import('./screens/SignUpScreen/SignUpScreen'))
)
const SignUpDialog = loadable(
  () => import('./screens/SignUpScreen/SignUpDialog')
)

const SignInScreen = wrapSuspenseScreen(
  loadable(() => import('./screens/SignInScreen/SignInScreen'))
)
const SignInDialog = loadable(
  () => import('./screens/SignInScreen/SignInDialog')
)

const AuthCodeScreen = wrapSuspenseScreen(
  loadable(() => import('./screens/AuthCodeScreen/AuthCodeScreen'))
)

const AuthCodeDialog = loadable(
  () => import('./screens/AuthCodeScreen/AuthCodeDialog')
)

const UnsubscribeScreen = wrapSuspenseScreen(
  loadable(() => import('./screens/UnsubscribeScreen/UnsubscribeScreen'))
)

const AdminNavigator = wrapSuspenseScreen(
  loadable(() => import('./navigators/AdminNavigator'))
)

const MaintenanceScreen = wrapSuspenseScreen(
  loadable(() => import('./screens/MaintenanceScreen/MaintenanceScreen'))
)

const DialogWrapper = wrapSuspenseComponent(
  loadable(() => import('./newComponents/Dialog/DialogWrapper'))
)

const GuildDonationDialog = loadable(
  () => import('./screens/GuildDonationScreen/GuildDonationDialog')
)

const EventAttendDialog = loadable(
  () => import('./screens/EventAttendScreen/EventAttendDialog')
)

const EventTicketPaymentDialog = loadable(
  () => import('./screens/EventTicketPaymentScreen/EventTicketPaymentDialog')
)

const createWildcardRedirect =
  (prefix: string): FC =>
  () => {
    const params = useParams()

    return (
      <Navigate
        to={[prefix, params['*']].filter(Boolean).join('/')}
        replace={true}
      />
    )
  }

const EventsRedirect = createWildcardRedirect('/events')
const PresentationsRedirect = createWildcardRedirect('/presentations')
const LondonGraphQLRedirect = createWildcardRedirect('/london-graphql')

const SentryRoutes =
  process.env.NODE_ENV === 'production'
    ? Sentry.withSentryReactRouterV6Routing(Routes)
    : Routes

export const App: FC<{
  relayEnvironment?: Environment
  accessToken?: string
  refreshToken?: string
  Router: ({
    children,
    onTransition,
  }: {
    children: React.ReactNode
    onTransition?: (transitioning: boolean) => any
  }) => JSX.Element
}> = ({ relayEnvironment, accessToken, refreshToken, Router }) => {
  useTitle('Elevate Your Community | Guild')
  useMeta({ property: 'og:title', content: 'Elevate Your Community | Guild' })

  useMeta({
    property: 'description',
    content:
      'Check out Events and Presentations from your favorite communities on Guild.',
  })
  useMeta({
    property: 'og:description',
    content: 'Elevate your community',
  })

  const [isLoading, setIsLoading] = useState(false)

  const serverContext = useContext(ServerContext)

  const tamaguiConfigWithBreakpoints = useMemo(() => {
    if (!serverContext) {
      return tamaguiConfig
    }

    return {
      ...tamaguiConfig,
      mediaQueryDefaultActive: {
        ...tamaguiConfig.mediaQueryDefaultActive,
        xs: serverContext.defaultBreakpoint <= 0,
        gtXs: serverContext.defaultBreakpoint > 0,
        sm: serverContext.defaultBreakpoint <= 1,
        gtSm: serverContext.defaultBreakpoint > 1,
        md: serverContext.defaultBreakpoint <= 2,
        gtMd: serverContext.defaultBreakpoint > 2,
        lg: serverContext.defaultBreakpoint <= 3,
        gtLg: serverContext.defaultBreakpoint > 3,
        mdHt: serverContext.defaultBreakpoint <= 3,
        gtMdHt: serverContext.defaultBreakpoint > 3,
      },
    }
  }, [serverContext?.defaultBreakpoint])

  if (MAINTENANCE_MODE) {
    return (
      <Router
        onTransition={(transitioning) => {
          setIsLoading(transitioning)
        }}
      >
        <TamaguiProvider
          config={tamaguiConfigWithBreakpoints}
          disableInjectCSS={true}
          disableRootThemeClass={true}
          defaultTheme='light'
        >
          <Provider tokens={tokens} components={lightComponents}>
            <ErrorBoundary>
              <AppContent isLoading={isLoading} />
            </ErrorBoundary>
          </Provider>
        </TamaguiProvider>
      </Router>
    )
  }

  return (
    <DataProvider
      initialAccessToken={accessToken}
      initialRefreshToken={refreshToken}
      relayEnvironment={relayEnvironment}
    >
      <Router
        onTransition={(transitioning) => {
          setIsLoading(transitioning)
        }}
      >
        <TamaguiProvider
          config={tamaguiConfigWithBreakpoints}
          disableInjectCSS={true}
          disableRootThemeClass={true}
          defaultTheme='light'
        >
          <Provider tokens={tokens} components={lightComponents}>
            <ErrorBoundary>
              <AppContent isLoading={isLoading} />
            </ErrorBoundary>
          </Provider>
        </TamaguiProvider>
      </Router>
    </DataProvider>
  )
}

const AppContainer = ({
  children,
  isLoading = false,
}: {
  children: React.ReactNode
  isLoading?: boolean
}) => {
  const data = useLazyLoadQuery<AppQuery>(
    graphql`
      query AppQuery {
        ...DynamicIslandProfile_query
      }
    `,
    {}
  )

  // const location = useLocation()

  // const isInitialLoad = useRef(true)

  // useIsomorphicEffect(() => {
  //   if (typeof window !== undefined) {
  //     if (isInitialLoad.current) {
  //       isInitialLoad.current = false
  //       return
  //     }

  //     window.scrollTo({ top: 0, left: 0, behavior: 'instant' })
  //   }
  // }, [location.state?.backgroundLocation?.pathname || location.pathname])

  return (
    <>
      <DynamicIsland query={data} isLoading={isLoading} />

      {children}

      <Footer />
    </>
  )
}

const AppContent = ({ isLoading = false }) => {
  const location = useLocation()
  const navigate = useNavigate()

  const locationState = location.state as { backgroundLocation?: Location }

  const mainLocation: Location = useMemo(() => {
    if (locationState?.backgroundLocation) {
      return {
        ...locationState.backgroundLocation,
        state: {
          ...(locationState.backgroundLocation.state || {}),
          inModal: true,
        },
      }
    }

    return { ...location, state: { ...(location.state || {}), inModal: false } }
  }, [locationState, location])

  if (MAINTENANCE_MODE) {
    return <MaintenanceScreen />
  }

  return (
    <SentryRoutes>
      <Route path='/embeds/*' element={<EmbedNavigator />} />

      <Route
        path='*'
        element={
          <AppContainer isLoading={isLoading}>
            <Routes location={mainLocation}>
              <Route path='/' element={<HomeScreen />} />
              <Route path='/guilds/*' element={<GuildsScreenNew />} />
              <Route path='/guilds/new' element={<GuildNewScreen />} />

              <Route path='/e/*' element={<EventsRedirect />} />

              <Route path='/events' element={<EventsScreen />} />
              <Route path='/events/new' element={<EventNewScreen />} />
              <Route
                path='/events/:eventId/*'
                element={<WrappedEventNavigator />}
              />

              <Route path='/p/*' element={<PresentationsRedirect />} />

              <Route path='/presentations' element={<PresentationsScreen />} />

              <Route
                path='/integrations/meetup'
                element={<MeetupIntegrationScreen />}
              />

              <Route
                path='/integrations/bluesky'
                element={<BlueskyIntegrationScreen />}
              />

              <Route
                path='/presentations/new'
                element={<PresentationNewScreen />}
              />
              <Route
                path='/presentations/:presentationId'
                element={<PresentationScreen />}
              />
              <Route
                path='/presentations/:presentationId/edit'
                element={<PresentationEditScreen />}
              />

              <Route path='/sign-in' element={<SignInScreen />} />
              <Route path='/sign-up' element={<SignUpScreen />} />

              <Route path='/auth/code' element={<AuthCodeScreen />} />

              <Route path='/unsubscribe' element={<UnsubscribeScreen />} />

              <Route path='/admin/*' element={<AdminNavigator />} />

              <Route
                path='/graphql-london/*'
                element={<LondonGraphQLRedirect />}
              />

              <Route path='/:actorId/*' element={<WrappedActorNavigator />} />
            </Routes>

            {!!locationState?.backgroundLocation && (
              <Routes>
                <Route
                  path='/presentations/new'
                  element={
                    <ModalWrapper>
                      <PresentationNewScreenContent />
                    </ModalWrapper>
                  }
                />

                <Route
                  path='/presentations/:presentationId'
                  element={
                    <ModalWrapper variant='noPadding'>
                      <PresentationScreenContentWrapper />
                    </ModalWrapper>
                  }
                />

                <Route
                  path='/presentations/:presentationId/edit'
                  element={
                    <ModalWrapper>
                      <PresentationEditScreenContent />
                    </ModalWrapper>
                  }
                />

                <Route
                  path='/events/new'
                  element={
                    <ModalWrapper variant='narrow'>
                      <EventNewContent />
                    </ModalWrapper>
                  }
                />

                <Route
                  path='/guilds/new'
                  element={
                    <ModalWrapper variant='narrow'>
                      <GuildNewContent />
                    </ModalWrapper>
                  }
                />

                <Route
                  path='/events/:eventId/settings/presentations/new'
                  element={
                    <ModalWrapper>
                      <EventEditPresentationNewContent
                        onCreate={() => navigate(-1)}
                      />
                    </ModalWrapper>
                  }
                />

                <Route
                  path='/events/:eventId/attend'
                  element={
                    <DialogWrapper>
                      <EventAttendDialog />
                    </DialogWrapper>
                  }
                />

                <Route
                  path='/events/:eventId/payment'
                  element={
                    <DialogWrapper
                      contentProps={{
                        $gtXs: { width: 576 - 40 },
                        $gtSm: { minWidth: 600 },
                      }}
                    >
                      <EventTicketPaymentDialog />
                    </DialogWrapper>
                  }
                />

                <Route
                  path='/sign-in'
                  element={
                    <DialogWrapper
                      contentProps={{
                        padding: 0,
                        width: 400,
                        maxWidth: 400,
                        minHeight: 300,
                        backgroundColor: 'none',
                      }}
                      sheetScrollViewProps={{
                        padding: 0,
                      }}
                    >
                      <SignInDialog />
                    </DialogWrapper>
                  }
                />

                <Route
                  path='/sign-up'
                  element={
                    <DialogWrapper
                      contentProps={{
                        padding: 0,
                        width: 400,
                        maxWidth: 400,
                        minHeight: 300,
                        backgroundColor: 'none',
                      }}
                      sheetScrollViewProps={{
                        padding: 0,
                      }}
                    >
                      <SignUpDialog />
                    </DialogWrapper>
                  }
                />

                <Route
                  path='/auth/code'
                  element={
                    <DialogWrapper
                      contentProps={{ minHeight: 300, padding: 0 }}
                    >
                      <AuthCodeDialog />
                    </DialogWrapper>
                  }
                />

                <Route
                  path='/:actorId/donate'
                  element={
                    <DialogWrapper>
                      <GuildDonationDialog />
                    </DialogWrapper>
                  }
                />
              </Routes>
            )}
          </AppContainer>
        }
      />
    </SentryRoutes>
  )
}

export default App
