import React, { useMemo, forwardRef } from 'react'
import {
  variableToString,
  getTokenValue,
  useTheme,
  Token,
  Spinner as TSpinner,
} from 'tamagui'
// import { ActivityIndicator } from 'react-native'

export const Spinner = forwardRef<
  typeof TSpinner,
  { color: Token; size: Token }
>(({ color, size, ...props }, ref) => {
  const theme = useTheme()

  const parsedColor = useMemo(() => {
    return getTokenValue(color, 'color')
  }, [color, theme])

  const parsedSize = useMemo(() => {
    return getTokenValue(size, 'size')
  }, [size])

  return (
    <TSpinner
      ref={ref}
      {...props}
      size={parsedSize}
      color={parsedColor}
      aria-label='Loading...'
    />
  )
})
