import { shorthands } from '@tamagui/shorthands'
import { createTamagui, createTokens, createFont } from 'tamagui'
import { createAnimations } from '@tamagui/animations-css'
import { createAnimations as createMotiAnimations } from '@tamagui/animations-moti'

import { transparentize } from 'polished'
import { Easing } from 'react-native-reanimated'

const BASE_FONT_SIZE = 16
const BASE_SPACE_SIZE = 4

// const SCALE_RATIO = (1 + Math.sqrt(5)) / 2 // Golden Ratio
const SCALE_RATIO = (1 + Math.pow(5, 0.25)) / 2 // "Half-step" Golden Ratio
// const SCALE_RATIO = 9 / 8

const size = (() => {
  const obj = { $true: BASE_SPACE_SIZE * 4 }

  Array.from({ length: 31 }).forEach((_, index) => {
    obj[`$${index}`] = BASE_SPACE_SIZE * index
  })

  return obj
})()

const createFontSizeScale = (baseScaleUnit: number, scaleRatio: number) => ({
  1: baseScaleUnit / Math.pow(scaleRatio, 4),
  2: baseScaleUnit / Math.pow(scaleRatio, 3),
  3: baseScaleUnit / Math.pow(scaleRatio, 2),
  4: baseScaleUnit / scaleRatio,
  5: baseScaleUnit,
  6: baseScaleUnit * scaleRatio,
  7: baseScaleUnit * Math.pow(scaleRatio, 2),
  8: baseScaleUnit * Math.pow(scaleRatio, 3),
  9: baseScaleUnit * Math.pow(scaleRatio, 4),
  10: baseScaleUnit * Math.pow(scaleRatio, 5),
  11: baseScaleUnit * Math.pow(scaleRatio, 6),
  12: baseScaleUnit * Math.pow(scaleRatio, 7),
  13: baseScaleUnit * Math.pow(scaleRatio, 8),
  14: baseScaleUnit * Math.pow(scaleRatio, 9),
  15: baseScaleUnit * Math.pow(scaleRatio, 10),
  16: baseScaleUnit * Math.pow(scaleRatio, 11),
  17: baseScaleUnit * Math.pow(scaleRatio, 12),
  18: baseScaleUnit * Math.pow(scaleRatio, 13),
  true: baseScaleUnit,
})

const tokens = createTokens({
  color: {
    grey0: '#FFFFFF',
    grey1: '#F8F7FC',
    grey2: '#F4F2FA',
    grey3: '#E9E7F1',
    grey4: '#D5D3DD',
    grey5: '#C5C3CF',
    grey6: '#9A97A8',
    grey7: '#696676',
    grey8: '#1B1340',
    grey9: '#000000',

    darkBlue0: transparentize(1.0, '#5533EB'),
    darkBlue1: transparentize(0.9, '#5533EB'),
    darkBlue2: transparentize(0.8, '#5533EB'),
    darkBlue3: transparentize(0.7, '#5533EB'),
    darkBlue4: transparentize(0.6, '#5533EB'),
    darkBlue5: transparentize(0.5, '#5533EB'),
    darkBlue6: transparentize(0.4, '#5533EB'),
    darkBlue7: transparentize(0.3, '#5533EB'),
    darkBlue8: transparentize(0.2, '#5533EB'),
    darkBlue9: transparentize(0.1, '#5533EB'),
    darkBlue10: '#5533EB',

    lightBlue0: transparentize(1.0, '#6E33EB'),
    lightBlue1: transparentize(0.9, '#6E33EB'),
    lightBlue2: transparentize(0.8, '#6E33EB'),
    lightBlue3: transparentize(0.7, '#6E33EB'),
    lightBlue4: transparentize(0.6, '#6E33EB'),
    lightBlue5: transparentize(0.5, '#6E33EB'),
    lightBlue6: transparentize(0.4, '#6E33EB'),
    lightBlue7: transparentize(0.3, '#6E33EB'),
    lightBlue8: transparentize(0.2, '#6E33EB'),
    lightBlue9: transparentize(0.1, '#6E33EB'),
    lightBlue10: '#6E33EB',

    red0: transparentize(1.0, '#FF627E'),
    red1: transparentize(0.9, '#FF627E'),
    red2: transparentize(0.8, '#FF627E'),
    red3: transparentize(0.7, '#FF627E'),
    red4: transparentize(0.6, '#FF627E'),
    red5: transparentize(0.5, '#FF627E'),
    red6: transparentize(0.4, '#FF627E'),
    red7: transparentize(0.3, '#FF627E'),
    red8: transparentize(0.2, '#FF627E'),
    red9: transparentize(0.1, '#FF627E'),
    red10: '#FF627E',
  },
  size,
  space: size,
  radius: size,
  zIndex: {
    0: 0,
    1: 100,
    2: 200,
    3: 300,
    4: 400,
    5: 500,
    true: 0,
  },
})

const lightTheme = {
  background: tokens.color.grey0,
  backgroundHover: tokens.color.grey2,
  backgroundPress: tokens.color.darkBlue5,
  color: tokens.color.grey8,
  borderColor: tokens.color.grey5,
  borderColorFocus: tokens.color.darkBlue9,
  borderColorHover: tokens.color.darkBlue9,

  primaryGradient: `linear-gradient(${tokens.color.darkBlue9.val}, ${tokens.color.darkBlue10.val})`,
  hoverGradient: `linear-gradient(${tokens.color.darkBlue7.val}, ${tokens.color.darkBlue8.val})`,

  shadowColor: tokens.color.grey6,
  primaryColor: tokens.color.darkBlue10,

  successColor: '#1a9e1a',
  errorColor: '#e14747',
  errorColorHover: '#930015',

  boxShadowLight:
    'rgba(10, 37, 64, 0.04) 0px 4px 8px -4px, rgba(10, 37, 64, 0.03) 0px 5px 26px -7px',
  boxShadowHeavy:
    'rgba(10, 37, 64, 0.12) 0px 4px 8px -4px, rgba(10, 37, 64, 0.09) 0px 5px 26px -7px',
  boxShadowTight:
    'rgba(10, 37, 64, 0.12) 0px 2px 8px, rgba(10, 37, 64, 0.09) 0px 4px 26px',
}

const FONT_FAMILY = [
  'Inter',
  '-apple-system',
  'system-ui',
  'BlinkMacSystemFont',
  '"Helvetica Neue"',
  'Helvetica',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Tweoji Mozilla"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
  '"Noto Color Emoji"',
  '"EmojiOne Color"',
  '"Android Emoji"',
].join(',')

const headingFont = createFont({
  family: FONT_FAMILY,
  weight: {
    1: '100',
    2: '200',
    3: '300',
    4: '400',
    5: '500',
    6: '600',
    7: '700',
    8: '800',
    9: '900',
    true: '600',
  },
  size: createFontSizeScale(BASE_FONT_SIZE, SCALE_RATIO),
  lineHeight: createFontSizeScale(BASE_FONT_SIZE * SCALE_RATIO, SCALE_RATIO),
})

const bodyFont = createFont({
  family: FONT_FAMILY,
  weight: {
    1: '100',
    2: '200',
    3: '300',
    4: '400',
    5: '500',
    6: '600',
    7: '700',
    8: '800',
    9: '900',
    true: '400',
  },
  size: createFontSizeScale(BASE_FONT_SIZE, SCALE_RATIO),
  lineHeight: createFontSizeScale(BASE_FONT_SIZE * SCALE_RATIO, SCALE_RATIO),
})

const cssAnimations = createAnimations({
  fast: 'cubic-bezier(0, 0.5, 1, 1) 125ms',
  medium: 'cubic-bezier(0, 0.5, 1, 1) 250ms',
  slow: 'cubic-bezier(0, 0.5, 1, 1) 375ms',
  debug: 'cubic-bezier(0, 0.5, 1, 1) 5s',
})

const reanimatedAnimations = createMotiAnimations({
  fast: { type: 'timing', duration: 125, easing: Easing.bezier(0, 0.5, 1, 1) },
  fastSpring: {
    damping: 50,
    mass: 2,
    stiffness: 500,
  },
  medium: {
    type: 'timing',
    duration: 250,
    easing: Easing.bezier(0, 0.5, 1, 1),
  },
  mediumSpring: {
    damping: 30,
    mass: 2,
    stiffness: 250,
  },
  slow: { type: 'timing', duration: 375, easing: Easing.bezier(0, 0.5, 1, 1) },
  slowSpring: {
    damping: 50,
    mass: 2,
    stiffness: 100,
  },
  debug: {
    type: 'timing',
    duration: 5 * 1000,
    easing: Easing.bezier(0, 0.5, 1, 1),
  },
})

const tamaguiConfig = createTamagui({
  defaultFont: 'body',
  shouldAddPrefersColorThemes: true,
  disableRootThemeClass: true, // done via web-worker SSR instead
  themeClassNameOnRoot: true,
  // settings: {
  //   allowedStyleValues: 'somewhat-strict-web',
  //   autocompleteSpecificTokens: 'except-special',
  //   // mediaPropOrder: true,
  // },
  themes: {
    light: lightTheme,
  },
  tokens,
  shorthands,
  animations: reanimatedAnimations,
  // animations: cssAnimations,
  fonts: {
    heading: headingFont,
    body: bodyFont,
  },
  media: {
    xs: { maxWidth: 576 },
    gtXs: { minWidth: 577 },
    sm: { maxWidth: 768 },
    gtSm: { minWidth: 769 },
    md: { maxWidth: 960 },
    gtMd: { minWidth: 961 },
    lg: { maxWidth: 1200 },
    gtLg: { minWidth: 1201 },
    mdHt: { maxHeight: 800 },
    gtMdHt: { minHeight: 801 },
    lgHt: { maxHeight: 900 },
    gtLgHt: { minHeight: 901 },
    xLgHt: { maxHeight: 1000 },
    gtXLgHt: { minHeight: 1001 },
    hoverNone: { hover: 'none' },
    pointerCoarse: { pointer: 'coarse' },
  },
  mediaQueryDefaultActive: {
    xs: false,
    gtXs: true,
    sm: false,
    gtSm: true,
    md: true,
    gtMd: false,
    lg: false,
    gtLg: false,
    mdHt: true,
    gtMdHt: false,
  },
})

export type Conf = typeof tamaguiConfig

declare module 'tamagui' {
  interface TamaguiCustomConfig extends Conf {}

  interface TypeOverride {
    groupNames(): 'card' | 'takeoutBody' | 'content'
  }
}

export default tamaguiConfig
